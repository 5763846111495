@import '../../scss/core/vars';

.birthdayBox {
    box-shadow: 0px 4px 4px rgba($colorBlack, 0.25);
    border-radius: 5px;
    background-color: $colorWhite;

    .boxHeader {
        border-bottom: 2px solid #e5e5e5;
        padding: 20px;
        display: flex;
        align-items: center;

        h3 {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: $colorText;
            margin-bottom: 0;
            margin-left: 20px;
        }
    }

    .birthdayList {
        margin: 0;
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;

        li {
            width: 33.33%;
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: center;
            padding: 8px;

            img {
                width: 100%;
                max-width: 56px;
                height: 56px;
                border-radius: 50%;
                object-fit: cover;
            }

            .name {
                font-size: 14px;
                line-height: 18px;
                color: $colorText;
                display: block;
            }

            &.today {
                img {
                    border: 4px solid #15599d;
                }
                span {
                    font-weight: bold;
                    color: #15599d !important;
                }
            }
        }
    }
}
