@import '../../scss/core/vars';

.usersRolesBox {
  margin-bottom: 30px;

  &:last-child{
    margin-bottom: 0;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: $colorText;
    margin-bottom: 0;
  }

  p {
    font-size: 14px;
    line-height: 23px;
    color: $colorText;
    text-decoration: none;
    margin-bottom: 0;
  }
}
