@import '../core/vars';

.login {
  min-height: 100vh;
  background-color: $colorPrimary;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/dots.svg');
    background-repeat: repeat space;
  }

  .logo{
    display: block;
    margin: 30px auto;
    position: relative;
    z-index: 99999;
    padding: 20px;
    background-color: $colorPrimary;
  }

  .form {
    background-color: $colorWhite;
    padding: 50px 42px;
    box-shadow: 0px 4px 4px rgba($colorBlack, 0.25);
    border-radius: 5px;
    position: relative;
    z-index: 99999;

    label {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: $colorText;
    }

    input {
      background: $colorGray;
      border: 2px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
}
