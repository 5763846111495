@import '../../scss/core/vars';

.postBox {
  background: $colorWhite;
  box-shadow: 0 4px 4px rgba($colorBlack, 0.25);
  border-radius: 5px;
  margin-bottom: 30px;

  .title {
    padding: 25px 30px;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: $colorPrimary;
    margin-bottom: 0;
  }

  .content {
    padding: 0 30px 25px 30px;

    p, li {
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      color: $colorText;
    }

    a{
      word-break: break-all;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 18px;
    }

    img, figure {
      max-width: 100%;
      height: auto;
    }
  }

  .postFooter {
    padding: 15px 30px;
    border-top: 2px solid #e5e5e5;

    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 23px;
      color: #808080;

      span{
        color: $colorPrimary;
        text-transform: uppercase;
      }
    }
  }
}
