@import '../../scss/core/vars';

.committee {
  margin-bottom: 30px;
  height: calc(100% - 30px);
  background: $colorWhite;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba($colorBlack, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wrapper {
    height: 100%;
  }

  .image {
    border-radius: 5px;
    width: 100%;
    height: auto;
    margin: 0 auto 30px auto;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: $colorText;
  }

  .content {
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      color: $colorText;
    }
  }
}
