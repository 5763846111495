@import '../../scss/core/vars';

.footer {
  position: relative;
  z-index: 9999;
  border-top: 2px solid #e5e5e5;
  padding: 16px;
  background-color: transparent;
  margin-top: 90px;
  
  p {
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    color: $colorPrimaryAlternative;
    margin-bottom: 0;
  }
  
  &.isBlue {
    border-top: 2px solid rgba($colorGray, 0.2);
    background-color: $colorPrimary;

    p {
      color: #c4c4c4;
    }
  }
}
