@import '../../scss/core/vars';

.roomContainer {
  border-top: 2px solid #e5e5e5;
  padding: 20px 0 0 0;
  margin-top: 40px;

  .list {
    margin: 0;
    padding: 0;
    display: flex;

    .item {
      list-style: none;
      padding: 10px;
      cursor: pointer;

      .wrapper {
        padding: 10px;
        border-radius: 6px;

        span {
          display: block;

          &.name {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            color: $colorText;
            margin-bottom: 5px;
          }

          &.qty {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $colorText;
          }
        }

        &.active,
        &:hover {
          background-color: $colorPrimaryAlternative;
        }
      }
    }
  }
}

.hoursList {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    display: inline-block;
    margin-right: 30px;

    &:last-child{
      margin-right: 0;
    }

    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 29px;
      display: block;
      color: $colorPrimary;
      cursor: pointer;

      &.active{
        background-color: $colorPrimary;
        color: $colorWhite;
        padding: 4px 16px;
        border-radius: 6px;
      }
    }
  }
}

.loading {
  display: block;
  width: 24px;
  height: 24px;
  border:3px solid $colorPrimary;
  border-radius:50%;
  border-left-color: transparent;

  /* Animation */
  animation: spinner linear infinite 500ms; 
}

@keyframes spinner {
  to {
    transform:rotate(360deg)
  }
}

.button {
  &:disabled {
    background:$colorPrimaryAlternative!important;
    cursor: no-drop!important;
  }
}