$colorPrimary: #15599d;
$colorPrimaryAlternative: #739bc4;
$colorSecondary: #1c1c1c;
$colorText: #363636;
$colorTextAlternative: #808080;
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorGray: #f5f5f5;
$fontDefault: 'Monda', sans-serif;
$fontSupport: 'Open Sans', sans-serif;
