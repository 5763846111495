@import '../../scss/core/vars';

.customCard {
  margin-bottom: 30px;
  height: calc(100% - 30px);
  background: $colorWhite;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba($colorBlack, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wrapper {
    height: 100%;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: $colorPrimary;

    &.gray {
      color: $colorText;
    }
  }

  .containerButton {
    display: flex;
    flex-direction: column-reverse;
  }


  .content {
    p {
      font-weight: normal;
      text-align: justify;
      font-size: 14px;
      line-height: 23px;
      color: $colorText;
    }
  }

  &.imageUrl {
    padding: 0;

    .wrapper {
      padding: 40px;
    }
  }
}