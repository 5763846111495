@import '../../scss/core/vars';
@import '../../scss/core/mixins';

.results {
  border-top: 2px solid #e5e5e5;
  padding: 20px;
  margin-top: 40px;

  p {
    color: #808080 !important;
  }
}

.customInput {
  border-radius: 50px !important;
  background-color: #f5f5f5 !important;
  border: 2px solid #e5e5e5 !important;
  outline: none;
  box-shadow: none !important;
  padding-left: 20px !important;
  padding-right: 20px !important;

  @include breakpoint(lg) {
    margin-bottom: 20px;
  }
}

.customSelect {
  border-radius: 50px !important;
  border: 2px solid $colorPrimary !important;
  box-shadow: none !important;
  text-align: center;
  cursor: pointer;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}
