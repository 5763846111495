@import url('https://fonts.googleapis.com/css2?family=Monda:wght@400;700&family=Open+Sans:wght@400;700&display=swap');
@import './bs5/bootstrap';
@import './core/vars';
@import './core/mixins';

html,
body {
  font-family: $fontDefault !important;
  background-color: #f5f5f5;
}

img {
  max-width: 100%;
  height: auto;
}

.navbar-brand {
  img {
    max-width: none;

    @include breakpoint(lg) {
      max-width: 120px;
    }
  }
}

.navbar-toggler {
  color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }
}

.button {
  background-color: $colorPrimaryAlternative;
  transition: all ease 0.3s;
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: $colorWhite;
  border-radius: 5px;
  width: 100%;
  border: none;

  @include breakpoint(lg) {
    margin-bottom: 15px;
  }

  &:hover {
    background-color: $colorPrimary;
  }

  &.round {
    border-radius: 50px;
    border: 2px solid $colorPrimaryAlternative;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;

    &:hover {
      border-color: $colorWhite;
    }
  }

  &.small {
    font-size: 14px;
    line-height: 28px;
    color: $colorWhite;
    background-color: transparent;
    text-transform: none;
  }
}

.customModal {
  .modal-content {
    box-shadow: 0px 4px 4px rgba($colorBlack, 0.25);
    border: none;
    padding: 40px;
  }

  .modal-body {
    padding: 0;
    text-align: center;

    h4 {
      font-weight: bold;
      font-size: 18px;
      line-height: 29px;
      text-align: center;
      color: #363636;
    }

    button {
      max-width: 285px;
      margin: 15px auto 0 auto;
    }
  }
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 39px;
  text-transform: uppercase;
  color: $colorTextAlternative;
  margin: 0 0 20px 0;

  &.blue {
    color: $colorPrimary !important;
  }

  &.typeTwo {
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: $colorText;
    text-transform: none;
  }

  span {
    color: $colorTextAlternative;
  }
}

.loadingSpinner {
  text-align: center;
  padding: 25px 0;
}

.nav-tabs {
  border-bottom: none;

  @include breakpoint(lg) {
    display: flex;
    flex-direction: column;
  }

  .nav-item {
    .nav-link {
      background-color: #e5e5e5;
      border-radius: 0;
      border: 2px solid #e5e5e5 !important;
      margin-bottom: 0;
      font-weight: 700;
      color: $colorText;

      &.active {
        background-color: $colorWhite;
      }

      @include breakpoint(lg) {
        width: 100%;
      }
    }

    &:first-child {
      .nav-link {
        border-radius: 3px 0 0 3px;
      }
    }

    &:last-child {
      .nav-link {
        border-radius: 0 3px 3px 0;
      }
    }
  }
}

.tab-content {
  padding-top: 20px;
}

.mb-30 {
  margin-bottom: 1.5rem;
}

.boxBordered {
  border: 2px solid #e5e5e5;
  padding: 30px 30px 10px 30px;
  height: 100%;

  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    color: $colorPrimaryAlternative;
    margin: 0 0 18px 0;
  }

  ul {
    margin: 0 0 30px 0;
    padding: 0;
  }

  .name {
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: $colorText;
    margin-bottom: 0;
    list-style: none;
  }

  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    color: #739BC4;
    margin-bottom: 32px;
  }

  .imageGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
    justify-items: center;
    align-items: center;
    padding: 10px 15px 30px 15px;

    @include breakpoint(xl) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(sm) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include breakpoint(xm) {
      grid-template-columns: repeat(2, 1fr);
    }

    img {
      max-width: 100%;
    }
  }
}

.accordion-item {
  border: none;

  .item {
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 0;
  }
}

.accordion-button {
  border-radius: 6px !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
  color: $colorPrimary;

  &::after {
    background-image: url('../assets/images/chevron-down.svg');
  }

  &:not(.collapsed) {
    color: $colorWhite;
    background-color: $colorPrimaryAlternative;
    box-shadow: none;

    &::after {
      background-image: url('../assets/images/chevron-up.svg');
      transform: rotate(0deg);
    }
  }

  &:focus {
    border: none;
    outline: 0;
    box-shadow: none;
  }
}

iframe {
  max-width: 100% !important;
}

.customInput {
  border-radius: 50px !important;
  background-color: #f5f5f5 !important;
  border: 2px solid #e5e5e5 !important;
  outline: none;
  box-shadow: none !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.customSelect {
  border-radius: 50px !important;
  border: 2px solid $colorPrimary !important;
  box-shadow: none !important;
  text-align: left;
  cursor: pointer;
  padding: 0 20px !important;
  line-height: 16px;
}

.wrapperSelect {
  display: flex;

  svg {
    margin-left: 10px;
  }
}

.schedules {
  .list {
    margin: 0;
    padding: 25px 15px;
    background-color: $colorWhite;

    li {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      .date {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: $colorPrimary;
        border: 2px solid $colorPrimary;
        border-radius: 5px;
        padding: 8px 10px;
        margin-right: 20px;
      }

      .title {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $colorText;
        text-transform: capitalize;
        margin-bottom: 0;

        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: $colorPrimary;
          display: block;
        }
      }

      &.today {
        .date {
          background-color: $colorPrimary;
          color: $colorWhite;
        }
      }
    }
  }
}


.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  line-height: 1.125em;
  margin-top: 30px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: $colorPrimaryAlternative;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: $colorPrimaryAlternative;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: $colorPrimary;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

/* Button */
.button-download {
  display: grid;
  grid-template-columns: 18px 1fr;
  justify-content: flex-start;
  align-items: center;
  text-align:left;
  color: #739bc4;
  border:2px solid #739bc4;
  gap: .5rem;
  &:focus {
    border-color:#739bc4;
    color:#739bc4;
  }
  &:hover {
    background:#15599d;
    border-color:#15599d;
    color:white;

    span {
      text-decoration:underline;
    }

    svg {
      fill:white;
    }
  }
  span {
    width: 100%;
    max-width: 340px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
} 