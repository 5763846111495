@import '../../scss/core/vars';

.usersEmailBox {
  margin-bottom: 30px;

  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: $colorText;
    margin-bottom: 0;
  }

  a {
    font-size: 14px;
    line-height: 23px;
    color: $colorPrimary;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }
}
