@import '../core/vars';

/* Gente
----------------------------- */ 
.usersRolesBox {
    margin-bottom: 30px;
  
    &:last-child{
      margin-bottom: 0;
    }

    h4 {
      font-size: 18px!important;
      color: $colorText!important;
    }
  
    p {
      font-size: 14px;
      line-height: 23px;
      color: $colorText;
      text-decoration: none;
      margin-bottom: 0;
    }
  }