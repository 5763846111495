@import '../../scss/core/vars';
@import '../../scss/core/mixins';

.button {
  border: 2px solid $colorPrimaryAlternative;
  border-radius: 5px;
  padding: 14px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: $colorPrimaryAlternative;
  text-decoration: none;
  transition: all ease 0.3s;

  @include breakpoint(lg){
    margin-bottom: 15px;
  }

  svg {
    margin-right: 10px;
    color: $colorPrimaryAlternative;
    transition: all ease 0.3s;
    
    path {
      fill: $colorPrimaryAlternative;
      transition: all ease 0.3s;
    }
  }
  
  span{
    max-width: calc(100% - 30px);
  }

  &.modelTwo {
    background-color: $colorPrimaryAlternative;
    color: $colorWhite;
  }

  &.centered {
    justify-content: center;
    text-align: center;
  }

  &:hover {
    background-color: $colorPrimary;
    color: $colorWhite;
    border: 2px solid $colorPrimary;

    svg {
      color: $colorWhite;

      path {
        fill: $colorWhite;
      }
    }
  }
}
