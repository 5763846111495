@import '../../scss/core/vars';

.lecturesEvents {
  box-shadow: 0px 4px 4px rgba($colorBlack, 0.25);
  border-radius: 5px;
  background-color: $colorWhite;

  .boxHeader {
    border-bottom: 2px solid #e5e5e5;
    padding: 20px;
    display: flex;
    align-items: center;

    h3 {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: $colorText;
      margin-bottom: 0;
      margin-left: 20px;
    }
  }

  .list {
    margin: 0;
    padding: 30px;

    li {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .date {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: $colorPrimary;
        border: 2px solid $colorPrimary;
        border-radius: 5px;
        padding: 8px 10px;
        margin-right: 20px;
      }

      .title {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $colorText;
      }

      &.today {
        .date {
          background-color: $colorPrimary;
          color: $colorWhite;
        }

        .title {
          font-weight: 700;
        }
      }
    }
  }
}
