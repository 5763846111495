@import '../../scss/core/vars';
@import '../../scss/core/mixins';

.postList {
  .title {
    @include breakpoint(lg) {
      margin: 40px 0 20px 0;
    }
  }
}
