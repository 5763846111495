@import '../../scss/core/vars';
@import '../../scss/core/mixins';

.topbar {
  background-color: $colorPrimary;
  height: 80px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px rgba($colorBlack, 0.25);

  @include breakpoint(lg) {
    height: auto;
  }

  .desk {
    display: block;
    width: 100%;

    @include breakpoint(lg) {
      display: none;
    }

    .logo {
      max-width: 100px;
      margin-right: 50px;
    }

    .searchBox {
      .inputSearch {
        max-width: 280px;
        margin: 0 auto;
        background-color: $colorPrimaryAlternative;
        border-radius: 50px;
        border: none;
        color: $colorWhite;
        font-size: 14px;
        line-height: 23px;
        padding: 8px 30px;

        &::placeholder {
          color: $colorWhite;
          font-size: 14px;
          line-height: 23px;
        }
      }
    }

    .buttons {
      display: flex;
      a, span {
        border-radius:24px;
        padding-inline: 1.5rem;
        transition:500ms;
      }
    }
  }

  .mobile {
    display: none;
    width: 100%;
    background-color: transparent !important;

    @include breakpoint(lg) {
      display: flex;
    }

    .logoProfile {
      .profile {
        flex-direction:column;
        .photo {
          margin-right: 0px;
        }
      }
    }
  }
}

.logoProfile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    .photo {
      margin-right: 14px;
    }
    span {
      display: block;

      &.name {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: $colorWhite;
      }

      &.role {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $colorWhite;
      }
    }
  }
}