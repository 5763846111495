@import '../../scss/core/vars';
@import '../../scss/core/mixins';

.verticalNavigation {
  
  @include breakpoint(lg) {
    margin: 40px 0 20px 0;

    &:not(.topbar) {
      display: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 12px;

      .link {
        display: inline-flex;
        padding: 8px 18px;
        border-radius: 50px;
        font-weight: bold;
        font-size: 15px;
        line-height: 26px;
        color: $colorText;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        transition: all ease 0.3s;

        &.active {
          background-color: #e5e5e5;
        }

        svg {
          margin-right: 10px;
          color: $colorText;
          fill: $colorText;
          transition: all ease 0.3s;

          path {
            fill: $colorText;
            transition: all ease 0.3s;
          }
        }

        &:hover {
          background-color: $colorPrimary;
          color: $colorWhite;

          svg {
            color: $colorWhite;
            fill: $colorWhite;

            path {
              fill: $colorWhite;
            }
          }
        }

        @include breakpoint(lg) {
          display: block;
          color: $colorWhite;

          svg {
            color: $colorWhite;
            fill: $colorWhite;

            path {
              fill: $colorWhite;
            }
          }

          &.active{
            color: $colorText;

            svg {
              color: $colorText;
              fill: $colorText;
  
              path {
                fill: $colorText;
              }
            }
          }
        }
      }
    }
  }
}
